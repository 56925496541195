
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/camelcase */
import { IonGrid, IonRow, IonCol } from '@ionic/vue';
import { defineComponent } from 'vue';
import axios from 'axios';
import config from '@/../public/assets/conf.json';
import SoldWasteBarChart from '../charts/SoldWasteBarChart.vue';

export default defineComponent({
    name: 'Sold Waste',
    components: {
        IonGrid, IonRow, IonCol,
        SoldWasteBarChart
    },
    props: {
        hub: { type: Number, required: true, default: -1 },
        destroy: { type: Boolean, default: false }
    },
    data() {
        return {
            sold_waste: [] as any[],
            filter: ''
        }
    },
    methods: {
        async loadSoldWaste() {
            //load last 30 days then append
            const endDate: any = new Date
            const startDate = (new Date(endDate.getTime() - 30 * 24 * 60 * 60000)).toISOString().split('T')[0]
            
            const payload = {
                loc_id: this.$props.hub,
                start_date: startDate
            }

            const uniquekey = 'cache:wma:sold_waste-' + this.$props.hub
            const cached = localStorage.getItem(uniquekey)
            if (cached) {
                this.sold_waste = JSON.parse(cached)
            }

            if (navigator.onLine) {
                try {
                    const resp = await axios.post(''.concat(config['host_ip'], config['host_port'], '/get_sold_waste/'), payload)
                    localStorage.setItem(uniquekey, JSON.stringify(resp.data))
                    this.sold_waste = resp.data
                } catch (err) { 
                    console.log(err)
                }
            }
        },
        getPercentage(val: any) {
            return (val / this.overallSoldWaste * 100).toFixed(1)
        },
        resetAllData() {
            this.sold_waste = []
            this.filter = ''
        }
    },
    computed: {
        overallSoldWaste(): any {
            let sum = 0.0
            for(const waste_type of this.sold_waste) {
                sum += parseFloat(waste_type.total_amount)
            }
            return sum.toFixed(2)
        }
    },
    watch: {
        hub: {
            handler: function(newVal) {
                if (newVal > -1) {
                    this.loadSoldWaste()
                }
            }
        },
        destroy: {
            handler: function(newVal) {
                if (newVal) {
                    this.resetAllData()
                }
            }
        }
    }
})
