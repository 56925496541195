<template>
    <div class="card">
        <b class="uppercase">Your Stock</b>
        <hr class="card-divider" />
        <input class="searchbar mt-1" type="text" placeholder="Search" v-model="filter" @input="filterStock" />
        <div class="mt-1">
            <table style="width: 100%">
                <thead>
                    <tr class="table-header">
                        <td class="cell-border">Waste Type</td>
                        <td class="cell-border">Item</td>
                        <td class="cell-border">Color</td>
                        <td class="cell-border">Aggregation</td>
                        <td class="cell-border">Amount (kg)</td>
                    </tr>
                </thead>
                <tbody class="hidescrollbar">
                    <tr v-for="item of filteredList" :key="item">
                        <td class="cell-border">{{ item.waste_type || '-' }}</td>
                        <td class="cell-border">{{ item.shape || '-' }}</td>
                        <td class="cell-border">{{ item.colour || '-' }}</td>
                        <td class="cell-border">{{ item.agg_state || '-' }}</td>
                        <td class="cell-border">{{ item.total_amount }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import axios from 'axios';
import config from '@/../public/assets/conf.json';

export default defineComponent({
    name: 'Your Stock',
    props: {
        hub: { type: Number, required: true, default: -1 },
        destroy: { type: Boolean, default: false }
    },
    data() {
        return {
            filteredList: [] as any[],
            stock: [] as any[],
            filter: ''
        }
    },
    methods: {
        async loadStock() {
            const uniquekey = 'cache:wma:stock-' + this.$props.hub
            const cached = localStorage.getItem(uniquekey)
            if (cached) {
                this.stock = JSON.parse(cached)
            }

            if (navigator.onLine) {
                try {
                    const resp = await axios.get(''.concat(config['host_ip'], config['host_port'], '/stock/', this.$props.hub.toString()))
                    localStorage.setItem(uniquekey, JSON.stringify(resp.data))
                    this.stock = resp.data
                } catch (err) { 
                    console.log(err)
                }
            }
                
            this.filterStock()
            //this.filteredList = this.stock.slice()
        },
        filterStock() {
            this.filteredList.splice(0, this.filteredList.length)
            
            this.filteredList = this.stock.filter( (item: any) => {
                return item.description.toLowerCase().indexOf(this.filter.toLowerCase()) > -1
            })
        },
        resetAllData() {
            this.filteredList = []
            this.stock = []
            this.filter = ''
        }
    },
    watch: {
        hub: {
            handler: function(newVal) {
                if(newVal > -1) {
                    this.loadStock()
                }
            }
        },
        destroy: {
            handler: function(newVal) {
                if (newVal) {
                    this.resetAllData()
                }
            }
        }
    }
})
</script>

<style scoped>
table {
  margin-bottom: 0;
  width: 100%;
}

table thead, table tbody tr {
    display:table;
    width:100%;
    table-layout:fixed;
}

.table-header {
    font-weight: bold;
}

.cell-border {
    border-bottom: 1px solid #a9d8f7;
    border-right: 1px solid #a9d8f7;
    border-left: 1px solid #a9d8f7;
    padding: 7px 0px 7px 7px;
}

.cell-border:first-child {
    border-left: 0;
}

.cell-border:last-child {
    border-right: 0;
}

table > tbody {
    max-height: 200px;
    height: 200px;
    overflow-y:scroll;
    display: block;
}

.hidescrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

::-webkit-scrollbar {
    width: 0px;  /* remove scrollbar space */
    background: transparent;  /* optional: just make scrollbar invisible */
}
</style>