
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/camelcase */
import { IonContent, modalController } from '@ionic/vue';
import { defineComponent } from 'vue';
import axios from 'axios';
import config from '@/../public/assets/conf.json';

export default defineComponent({
    name: 'Modal',
    props: {
        id: { type: String, required: true },
        editable: { type: Boolean, default: false },
        hubId: { type: String, required: true }
    },
    components: { IonContent },
    data() {
        return {
            transaction: null as any,
            aggregated_transactions: [] as any[],
            p_transactions: [] as any[],
            f_transactions: [] as any[],
            subject: '',
            reason: '',
            claim: '',
            editDisabled: true,
            status: ''
        }
    },
    mounted() {
        this.loadDetail()
    },
    methods: {
        async loadDetail() {
            const uniquekey = 'cache:wma:transaction-num' + this.$props.id
            const cached = localStorage.getItem(uniquekey)
            if (cached) {
                const parsed = JSON.parse(cached)
                this.transaction = parsed.p_transaction || null
                this.p_transactions = parsed.p_transactions || []
                this.f_transactions = parsed.f_transactions || []
            }

            if (navigator.onLine) {
                try {
                    const resp = await axios.get(''.concat(config['host_ip'], config['host_port'], "/transactions/physical/detail/", this.$props.id))
                    localStorage.setItem(uniquekey, JSON.stringify(resp.data))
                    this.transaction = resp.data.p_transaction || null
                    this.p_transactions = resp.data.p_transactions || []
                    this.f_transactions = resp.data.f_transactions || []
                } catch (err) { 
                    console.log(err)
                }
            }

            for ( const p of this.p_transactions) {
                const financialEntry = this.f_transactions.find((el: any) => { return el.waste_data.id == p.waste_data.id })
                if (financialEntry) {
                    const oldPrice = (Number(financialEntry.transaction_data.value) / Number(financialEntry.amount)).toFixed(2)
                    this.aggregated_transactions.push(
                        {'waste': p.waste_data, 'p_waste_id': p.id, 'f_waste_id': financialEntry.id, 'f_id': financialEntry.transaction_data.id, 
                        'value': financialEntry.transaction_data.value, 'price': oldPrice,
                        'amount': p.amount, 'old_amount': financialEntry.amount})
                } else {
                    this.aggregated_transactions.push({'waste': p.waste_data, 'p_waste_id': p.id, 'amount': p.amount, 'old_amount': p.amount})
                }
            }
            console.log(this.aggregated_transactions)

            this.subject = this.getSubject()
            this.reason = this.transaction?.reason
            this.claim = this.transaction?.claim_message
        },
        getSubject() {
            if (this.transaction?.in_user_data) {
                return ''.concat(this.transaction.in_user_data?.user_data?.first_name, ' ', this.transaction.in_user_data?.user_data?.last_name)
            }

            if (this.transaction?.out_user_data) {
                return ''.concat(this.transaction.out_user_data?.user_data?.first_name, ' ', this.transaction.out_user_data?.user_data?.last_name)
            }

            if (this.transaction?.in_location_data?.id != this.$props.hubId) {
                return this.transaction?.in_location_data?.name
            }

            if (this.transaction?.out_location_data?.id != this.$props.hubId) {
                return this.transaction?.out_location_data?.name
            }
            return '-'
        },
        getState() {
            if (!this.transaction?.is_executed && !this.transaction?.starting_date) {
                return 'Shipping soon'
            }

            if (!this.transaction?.is_executed && this.transaction?.starting_date) {
                const dt = new Date(this.transaction.starting_date)
                return ''.concat('Shipped (', dt.toLocaleString(), ')')
            }

            if (this.transaction?.is_executed) {
                return 'Confirmed'
            }
            
            if (this.transaction?.is_cancelled) {
                return 'Rejected'
            }
            return '?'
        },
        checkValue(event: any, wasteRef: any) {
            if (event.data != '.' && isNaN(event.data) ) {
                event.target.value = wasteRef.old_amount
                wasteRef.amount = wasteRef.old_amount
            }

            if (Number(event.target.value) < Number(event.target.min)) {
                event.target.value = Number(event.target.min)
                wasteRef.amount = Number(event.target.min)
            }
        },
        enableEdit() {
            this.editDisabled = false
        },
        closeModal(insert: any) {
            if (insert) {
                for (const w of this.aggregated_transactions) {
                    w.value = Number(Number(w.price) * Number(w.amount)).toFixed(2)
                }
                
                const payload = {
                    'transactions': this.aggregated_transactions.filter((el: any) => { return el.amount != el.old_amount})
                }
                axios.put(''.concat(config['host_ip'], config['host_port'], "/transactions/mixed/", this.transaction.id.toString()), payload)
                .then((response) => {
                    console.log(response)
                    modalController.dismiss(true)
                })
                .catch((error) => {
                    this.parseQueryResult(error.response)
                });
            } else {
                modalController.dismiss(false)
            }
        },
        parseQueryResult(resp: any) {
            if (resp?.status == 400) {
                this.status = JSON.stringify(resp.data).replaceAll(/[{}_]/g, ' ')
            }
            else {
                this.status = JSON.stringify(resp.status)
            }
        }
    }
});
