
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/camelcase */
import { IonIcon } from '@ionic/vue';
import { defineComponent } from 'vue';
import axios from 'axios';
import config from '@/../public/assets/conf.json';

export default defineComponent({
    name: 'Hub Balance',
    components: { IonIcon },
    props: {
        hub: { type: Number, required: true, default: -1 },
        destroy: { type: Boolean, default: false }
    },
    data() {
        return {
            balance: {},
            last24hBalance: {},
            last30dBalance: {}
        }
    },
    methods: {
        loadAllData() {
            this.loadActual()
            this.loadLast24h()
            this.loadLast30h()
        },
        resetAllData() {
            this.balance = {}
            this.last24hBalance = {}
            this.last30dBalance = {}
        },
        async loadActual() {

            const payload = {
                loc_id: this.$props.hub
            }

            const uniquekey = 'cache:wma:actual_balance-' + this.$props.hub
            const cached = localStorage.getItem(uniquekey)
            if (cached) {
                this.balance = JSON.parse(cached)
            }

            if (navigator.onLine) {
                try {
                    const resp = await axios.post(''.concat(config['host_ip'], config['host_port'], '/transactions/location-balance/money'), payload)
                    localStorage.setItem(uniquekey, JSON.stringify(resp.data))
                    this.balance = resp.data
                } catch (err) { 
                    console.log(err)
                }
            }
            
        },
        async loadLast24h() {
            //load last 24 hours then append
            let endDate: any = new Date
            const end = endDate.toISOString().split('T')[0]
            const startDate = (new Date(endDate.getTime() - 24 * 60 * 60000)).toISOString().split('T')[0]
            endDate = end

            const payload = {
                loc_id: this.$props.hub,
                start_date: startDate, 
                end_date: endDate
            }

            const uniquekey = 'cache:wma:balancel24-' + this.$props.hub
            const cached = localStorage.getItem(uniquekey)
            if (cached) {
                this.last24hBalance = JSON.parse(cached)
            }

            if (navigator.onLine) {
                try {
                    const resp = await axios.post(''.concat(config['host_ip'], config['host_port'], '/transactions/location-balance/money'), payload)
                    localStorage.setItem(uniquekey, JSON.stringify(resp.data))
                    this.last24hBalance = resp.data
                } catch (err) { 
                    console.log(err)
                }
            }
        },
        async loadLast30h() {
            //load last 30 days then append
            let endDate: any = new Date
            const end = endDate.toISOString().split('T')[0]
            const startDate = (new Date(endDate.getTime() - 30 * 24 * 60 * 60000)).toISOString().split('T')[0]
            endDate = end

            const payload = {
                loc_id: this.$props.hub,
                start_date: startDate, 
                end_date: endDate
            }

            const uniquekey = 'cache:wma:balancel30-' + this.$props.hub
            const cached = localStorage.getItem(uniquekey)
            if (cached) {
                this.last30dBalance = JSON.parse(cached)
            }

            if (navigator.onLine) {
                try {
                    const resp = await axios.post(''.concat(config['host_ip'], config['host_port'], '/transactions/location-balance/money'), payload)
                    localStorage.setItem(uniquekey, JSON.stringify(resp.data))
                    this.last30dBalance = resp.data
                } catch (err) { 
                    console.log(err)
                }
            }
        },
    },
    watch: {
        hub: {
            handler: function(newVal) {
                if (newVal > -1) {
                    this.loadAllData()
                }
            }
        },
        destroy: {
            handler: function(newVal) {
                if (newVal) {
                    this.resetAllData()
                }
            }
        }
    }
})
