<template>
    <div class="card" style="min-height: 29.7vw">
        <div style="display: flex">
            <b class="uppercase">Transactions</b>
            <div style="margin-left: auto">
                <b>
                    <div class="pager-dropdown">
                        <b @click="openPageList()" class="dropbtn clickable">
                            <ion-icon class="dropbtn" style="margin-left: 20px" name="down-arrow"></ion-icon>
                            {{ getRangePageInfo() }}
                        </b>
                        <div id="pager-dropdown" class="pager-dropdown-content clickable">
                            <b v-for="p of Array(lastPage).keys()" @click="changeSpecificPage(p)" :key="p">Page {{p + 1}}</b>
                        </div>
                    </div>
                 of {{ total_count }}
                </b>
                <ion-icon class="padded-btn disabled" style="margin-left: 20px" v-if="page == 0" name="ch-back"></ion-icon>
                <ion-icon class="padded-btn clickable" style="margin-left: 20px" @click="changePage(true)" v-else name="ch-back"></ion-icon>
                <ion-icon class="padded-btn disabled" v-if="isLastPage()" name="ch-forward"></ion-icon>
                <ion-icon class="padded-btn clickable" @click="changePage(false)" v-else name="ch-forward"></ion-icon>
            </div>
        </div>
        <hr class="card-divider" />
        <!--input class="searchbar mt-1" type="text" placeholder="Search" v-model="filter" @input="filterStock" /-->
        <div class="mt-1">
            <table style="width: 100%; font-size: 0.9em">
                <thead>
                    <tr class="table-header">
                        <td colspan="1" class="cell-border">Status</td>
                        <td colspan="2" class="cell-border">Date</td>
                        <td colspan="2" class="cell-border">Reason</td>
                        <td colspan="3" class="cell-border">Waste Type</td>
                        <td colspan="1" class="cell-border">Amount</td>
                        <td colspan="1" class="cell-border">Payment</td>
                        <td colspan="2" class="cell-border">Subject</td>
                        <td colspan="2" class="cell-border">Transport Status</td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item of pending" :key="item">
                        <td colspan="1" class="cell-border hover-dropdown">
                            <div class="flex clickable">
                                <div class="pending-circle ion-hide-lg-down"></div>
                                <div class="bold-text" style="margin-left: 0.3vw;">Open</div>
                                <div class="hover-dropdown-content bold-text">
                                    <a @click="showDetail(item.id, item.in_location_data.id == hub)">See Details</a>
                                    <a @click="sendConfirm(item.id)" v-if="item.out_location_data.id == hub">Confirm</a>
                                    <a @click="sendReject(item.id)">Reject</a>
                                    <a @click="addClaim(item.id)" v-if="item.out_location_data.id == hub" :class="item.claim_message.length > 0 ? 'disabled-option': ''">Claim</a>
                                    <a @click="notifyStart(item.id)" v-if="item.in_location_data.id == hub" :class="!item.starting_date ? '': 'disabled-option'">Notify Start</a>
                                </div>
                            </div>
                        </td>
                        <td colspan="2" class="cell-border">{{ parseDate(item) }}</td>
                        <td colspan="2" class="cell-border">{{ item.reason }}</td>
                        <td colspan="3" class="cell-border">+</td>
                        <td colspan="1" class="cell-border">{{ item.amount }} kg</td>
                        <td colspan="1" class="cell-border bold-text">
                            <text :class="computeSign(item, true) > 0 ? 'positive' : 'negative'">{{ computeSign(item, true) }}</text> {{ convRate.currency }}
                        </td>
                        <td colspan="2" class="cell-border">{{ getSubject(item) }}</td>
                        <td colspan="2" class="cell-border">{{ getTransportStatus(item) }}</td>
                    </tr>
                    <tr v-for="item of transactions" :key="item" :class="getStatus(item.transaction_data) == 'Rejected' ? 'disabled' : ''">
                        <td colspan="1" class="cell-border disabled">{{ getStatus(item.transaction_data) }}</td>
                        <td colspan="2" class="cell-border">{{ parseDate(item.transaction_data) }}</td>
                        <td colspan="2" class="cell-border">{{ item.transaction_data.reason }}</td>
                        <td colspan="3" class="cell-border">{{ item.waste_data.description }}</td>
                        <td colspan="1" class="cell-border">{{ item.amount }} {{ item.waste_data.measure_unit }}</td>
                        <td colspan="1" class="cell-border bold-text">
                            <text :class="[getStatus(item.transaction_data) == 'Rejected' ? 'disabled' : '', computeSign(item) > 0 ? 'positive' : 'negative']">
                                {{ computeSign(item) }}
                            </text> {{ item.transaction_data.currency }}
                        </td>
                        <td colspan="2" class="cell-border">{{ getSubject(item.transaction_data) }}</td>
                        <td colspan="2" class="cell-border">{{ getTransportStatus(item.transaction_data) }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script lang="ts">
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/camelcase */
import { IonIcon, alertController, modalController } from '@ionic/vue';
import { defineComponent } from 'vue';
import axios from 'axios';
import config from '@/../public/assets/conf.json';
import TransactionDetail from './modals/TransactionDetail.vue';
import { updateTransaction } from '@/common/records';

export default defineComponent({
    name: 'Transactions',
    props: {
        hub: { type: Number, required: true, default: -1 },
        destroy: { type: Boolean, default: false }
    },
    components: { IonIcon },
    data() {
        return {
            pending: [] as any[],
            transactions: [] as any[],
            convRate: {} as any,
            total_count: 0,
            max_range: 15,
            page: 0
        }
    },
    mounted() {
        this.loadConversionRate()

        window.onclick = (event: any) => {
            if (event && !event?.target?.matches('.dropbtn')) {
                const dropdowns = document.getElementsByClassName("pager-dropdown-content");
                for (let i = 0; i < dropdowns.length; i++) {
                    const openDropdown = dropdowns[i];
                    if (openDropdown.classList.contains('show')) {
                        openDropdown.classList.remove('show');
                    }
                }
            }
        }
    },
    methods: {
        async loadConversionRate() {
            const uniquekey = 'cache:wma:conversion-rate'
            const cached = localStorage.getItem(uniquekey)
            if (cached) {
                const parsed = JSON.parse(cached)
                this.convRate = parsed[0]
            }

            if (navigator.onLine) {
                try {
                    const resp = await axios.get(''.concat(config['host_ip'], config['host_port'], "/conversion_rate/"))
                    localStorage.setItem(uniquekey, JSON.stringify(resp.data))
                    this.convRate = resp.data[0]
                } catch (err) { 
                    console.log(err)
                }
            }
        },
        async loadTransactions(start?: number, end?: number) {
            const payload = {
                location: this.$props.hub.toString(),
                start_idx: start || 0,
                end_idx: end || this.max_range
            }
            
            const uniquekey = 'cache:wma:transactions-' + this.$props.hub + '_' + payload.start_idx + '-' + payload.end_idx
            const cached = localStorage.getItem(uniquekey)
            if (cached) {
                const parsed = JSON.parse(cached)
                this.pending = parsed.pending || []
                this.transactions = parsed.transactions || []
                this.total_count = parsed.total_count || 0
            } else {
                this.pending = []
                this.transactions = []
            }

            if (navigator.onLine) {
                try {
                    const resp = await axios.post(''.concat(config['host_ip'], config['host_port'], '/transactions/financial_waste_list/'), payload)
                    localStorage.setItem(uniquekey, JSON.stringify(resp.data))
                    this.pending = resp.data.pending || []
                    this.transactions = resp.data.transactions || []
                    this.total_count = resp.data.total_count || 0
                } catch (err) { 
                    console.log(err)
                }
            }
        },
        openPageList() {
            console.log(document.getElementById("pager-dropdown"))
            console.log(document.getElementById("pager-dropdown")?.classList)
            document.getElementById("pager-dropdown")?.classList.toggle("show");
        },
        getSubject(transaction: any) {
            if (transaction.in_user_data) {
                return ''.concat(transaction.in_user_data?.user_data?.username)
            }

            if (transaction.out_user_data) {
                return ''.concat(transaction.out_user_data?.user_data?.username)
            }

            if (transaction.in_location_data?.id != this.$props.hub) {
                return transaction.in_location_data?.name
            }

            if (transaction.out_location_data?.id != this.$props.hub) {
                return transaction.out_location_data?.name
            }
            return '-'
        },
        getStatus(item: any) {
            if (item.is_cancelled) {
                return 'Rejected'
            }

            if (item.is_executed) {
                return 'Confirmed'
            } else {
                return '?'
            }
        },
        getTransportStatus(item: any) {
            if (!item.in_location_data || !item.out_location_data) {
                return '-'
            }

            if (item.is_executed) {
                return 'Delivered'
            }

            if (item.is_cancelled) {
                return 'Cancelled'
            }

            if (item.starting_date) {
                const dt = new Date(item.starting_date)
                return 'Shipped (' + dt.toLocaleDateString() + ')'
            } else {
                return 'Shipping Soon'
            }
        },
        parseDate(item: any) {
            return (new Date(item.execution_date || item.insertion_date)).toLocaleString()

        },
        computeSign(transaction: any, pending=false) {
            if (pending) {
                if (transaction?.in_location_data?.id == this.$props.hub) {
                    return transaction?.value
                } else {
                    return -1 * transaction?.value
                }
            }

            if (transaction.transaction_data?.in_location_data?.id == this.$props.hub) {
                return -1 * transaction.transaction_data?.value
            } else {
                return transaction.transaction_data?.value
            }
        },
        getRangePageInfo() {
            const start = this.getStartIdx
            const end = this.getEndIdx
            return ''.concat(start.toString(), ' - ', end.toString())
        },
        isLastPage() {
            return this.page == Math.floor(this.total_count / this.max_range)
        },
        changePage(isBack: boolean) {
            if (isBack) {
                this.page--
            } else {
                this.page++
            }
            this.loadTransactions(this.getStartIdx - 1, this.getEndIdx)
        },
        changeSpecificPage(numPage: number) {
            this.page = numPage
            const dropdowns = document.getElementsByClassName("pager-dropdown-content");
            for (let i = 0; i < dropdowns.length; i++) {
                const openDropdown = dropdowns[i];
                if (openDropdown.classList.contains('show')) {
                    openDropdown.classList.remove('show');
                }
            }
            this.loadTransactions(this.getStartIdx - 1, this.getEndIdx)
        },
        resetAllData() {
            this.pending = []
            this.transactions = []
            this.total_count = 0
            this.max_range = 15
            this.page = 0
        },
        async showDetail(transactionId: number, editable: boolean) {
            const modal = await modalController.create({
                component: TransactionDetail,
                cssClass: 'transactionDetailModal',
                componentProps: {
                    id: transactionId.toString(),
                    editable: editable,
                    hubId: this.$props.hub.toString()
                },
            })
            modal.present();

            modal.onDidDismiss().then((data) => {
            if (data.data) {
                this.loadTransactions(this.getStartIdx - 1, this.getEndIdx)
            }
        });

        },
        async showMsgAlert(msg: string) {
            let resolveFunction: (confirm: boolean) => void;
            const promise = new Promise<boolean>(resolve => {
                resolveFunction = resolve;
            });
            const alert = await alertController.create({
                cssClass: 'status-alert',
                header: msg,
                message: 'Once confirmed, you cannot go back!',
                buttons: [
                    {
                        text: 'Okay',
                        handler: () => {
                            console.log('Confirmed')
                            resolveFunction(true)
                        },
                    },
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            console.log('Cancelled')
                            resolveFunction(false)
                        },
                    }
                ],
            });

            await alert.present();
            return promise
        },
        async sendConfirm(transactionId: number) {
            const confirm = await this.showMsgAlert('Are you sure to confirm the execution of this transaction?');
            if (confirm) {
                const tForm = {
                    'is_executed': true, 
                    'execution_date': (new Date()).toISOString()
                }

                if (navigator.onLine) {
                    await updateTransaction(transactionId.toString(), tForm)
                    this.$router.go(0)
                } else {
                    const uniquekey = 'cache:wma:records'
                    let cached: any = localStorage.getItem(uniquekey)
                    if (cached) {
                        cached = JSON.parse(cached)
                    } else {
                        cached = JSON.parse('[]')
                    }
                    cached.push({
                        'payloads': [transactionId.toString(), tForm],
                        'type': 'Confirm Transaction',
                        'status': 'pending'})
                    localStorage.setItem(uniquekey, JSON.stringify(cached))
                }
            } else {
                console.log('no');
            }
            return null
        },
        async sendReject(transactionId: number) {
            const confirm = await this.showMsgAlert('Are you sure to reject this transaction?');
            if (confirm) {
                console.log('yes');
                const tForm = {
                    'is_cancelled': true
                }

                if (navigator.onLine) {
                    await updateTransaction(transactionId.toString(), tForm)
                    this.$router.go(0)
                } else {
                    const uniquekey = 'cache:wma:records'
                    let cached: any = localStorage.getItem(uniquekey)
                    if (cached) {
                        cached = JSON.parse(cached)
                    } else {
                        cached = JSON.parse('[]')
                    }
                    cached.push({
                        'payloads': [transactionId.toString(), tForm],
                        'type': 'Reject Transaction',
                        'status': 'pending'})
                    localStorage.setItem(uniquekey, JSON.stringify(cached))
                }
            } else {
                console.log('no');
            }
            return null
        },
        async showTypeAlert() {
            let resolveFunction: (returnmsg: string) => void;
            const promise = new Promise<string>(resolve => {
                resolveFunction = resolve;
            });
            const alert = await alertController.create({
                cssClass: 'status-alert',
                header: 'What\'s wrong with this transaction?',
                inputs: [
                    {
                        name: 'claim',
                        placeholder: 'What\'s happens?'
                    }
                ],
                buttons: [
                    {
                        text: 'Okay',
                        handler: (data: any) => {
                            console.log('Confirmed')
                            resolveFunction(data.claim)
                        },
                    },
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            console.log('Cancelled')
                            resolveFunction('')
                        },
                    }
                ],
            });

            await alert.present();

            return promise
        },
        async addClaim(transactionId: number) {
            const msg = await this.showTypeAlert()
            if (msg.length > 0) {
                const tForm = {
                    'claim_message': msg
                }

                if (navigator.onLine) {
                    await updateTransaction(transactionId.toString(), tForm)
                    this.loadTransactions(this.getStartIdx - 1, this.getEndIdx)
                } else {
                    const uniquekey = 'cache:wma:records'
                    let cached: any = localStorage.getItem(uniquekey)
                    if (cached) {
                        cached = JSON.parse(cached)
                    } else {
                        cached = JSON.parse('[]')
                    }
                    cached.push({
                        'payloads': [transactionId.toString(), tForm],
                        'type': 'Claim Transaction',
                        'status': 'pending'})
                    localStorage.setItem(uniquekey, JSON.stringify(cached))
                }
            } else {
                console.log('no');
            }
            return null
        },
        async notifyStart(transactionId: number) {
            const confirm = await this.showMsgAlert('Are you sure to notify the start of this transaction?');
            if (confirm) {
                const dt = new Date()
                const year = dt.getFullYear().toString()
                const month = (dt.getMonth() + 1).toString()
                const day = dt.getDate().toString()
                const dtFormatted = ''.concat(year, '-', month, '-', day)

                const tForm = {
                    'starting_date': dtFormatted
                }

                if (navigator.onLine) {
                    await updateTransaction(transactionId.toString(), tForm)
                    this.loadTransactions(this.getStartIdx - 1, this.getEndIdx)
                } else {
                    const uniquekey = 'cache:wma:records'
                    let cached: any = localStorage.getItem(uniquekey)
                    if (cached) {
                        cached = JSON.parse(cached)
                    } else {
                        cached = JSON.parse('[]')
                    }
                    cached.push({
                        'payloads': [transactionId.toString(), tForm],
                        'type': 'Notify Start Transaction',
                        'status': 'pending'})
                    localStorage.setItem(uniquekey, JSON.stringify(cached))
                }
            } else {
                console.log('no');
            }
            return null
        }
    },
    computed: {
        getStartIdx(): any {
            return Math.min((this.page * this.max_range) + 1, this.getEndIdx)
        },
        getEndIdx(): any {
            return Math.min((this.page + 1) * this.max_range, this.total_count)
        },
        lastPage(): number {
            return Math.ceil(this.total_count / this.max_range)
        }
    },
    watch: {
        hub: {
            handler: function(newVal) {
                if (newVal > -1) {
                    this.loadTransactions()
                }
            }
        },
        destroy: {
            handler: function(newVal) {
                if (newVal) {
                    this.resetAllData()
                }
            }
        }
    }
})
</script>

<style scoped>
table {
  margin-bottom: 0;
  width: 100%;
}

table thead, table tbody tr {
    display:table;
    width:100%;
    table-layout:fixed;
}

.table-header {
    font-weight: bold;
    font-size: 15px;
}

.cell-border {
    border-bottom: 1px solid #a9d8f7;
    border-right: 1px solid #a9d8f7;
    border-left: 1px solid #a9d8f7;
    padding: 7px 0px 7px 7px;
}

.cell-border:first-child {
    border-left: 0;
}

.cell-border:last-child {
    border-right: 0;
}

.padded-btn {
    margin-left: 5px;
    margin-right: 5px;
}

.disabled {
    color: grey!important;
}

.positive {
    color: green;
}

.negative {
    color: red;
}

.enabled {
    color: black;
}

.pending-circle {
    width: 10px;
    height: 10px;
    background-color: #fdb32a;
    border-radius: 50%;
    margin-top: auto;
    margin-bottom: auto;
}

/* The container <div> - needed to position the dropdown content */
.hover-dropdown {
    position: relative;
}

/* Dropdown Content (Hidden by Default) */
.hover-dropdown-content {
    display: none;
    position: absolute;
    top: 30px;
    background-color: white;
    border: 1px solid #81b9ee;
    border-radius: 10px;
    /*min-width: 67.7vw;*/
    z-index: 1;
}

/* Links inside the dropdown */
.hover-dropdown-content a {
    color: black;
    padding: 10px 16px;
    text-decoration: none;
    display: block;
    border-radius: 10px;
}

/* Change color of dropdown links on hover */
.hover-dropdown-content a:hover {
    background-color: #f1f1f1;
}

/* Show the dropdown menu on hover */
.hover-dropdown:hover .hover-dropdown-content {
    display: block;
}


.disabled-option {
    color: grey!important;
    pointer-events: none;
    cursor: none;
}

/* Dropdown Button */
.pager-dropbtn {
    background-color: #3498DB;
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
    cursor: pointer;
}

/* Dropdown button on hover & focus */
.pager-dropbtn:hover, .dropbtn:focus {
    background-color: #2980B9;
}

/* The container <div> - needed to position the dropdown content */
.pager-dropdown {
    position: relative;
    display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.pager-dropdown-content {
    display: none;
    position: absolute;
    background-color: white;
    min-width: 10vw;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    max-height: 300px;
    overflow-y: auto;
}

/* Links inside the dropdown */
.pager-dropdown-content b {
    padding: 7.5px 15px;
    display: block;
}

/* Change color of dropdown links on hover */
.pager-dropdown-content b:hover {background-color: #ddd}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.show {display:block;}
</style>
