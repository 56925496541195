
import { IonPage, IonContent, IonGrid, IonCol, IonRow, IonIcon } from '@ionic/vue';
import { defineComponent } from 'vue';
import Stock from './components/ownLocation/Stock.vue';
import Balance from './components/ownLocation/Balance.vue';
import PriceList from './components/ownLocation/PriceList.vue';
import SoldWaste from './components/ownLocation/SoldWaste.vue';
import WasteAvailability from './components/ownLocation/WasteAvailability.vue';
import Transactions from './components/ownLocation/Transactions.vue';
import config from '@/../public/assets/conf.json';
import axios from 'axios';

export default defineComponent({
  name: 'Your Hub',
  components: {
    IonPage, IonContent, IonGrid, IonCol, IonRow, IonIcon,
    WasteAvailability, Stock, Balance, PriceList, SoldWaste, Transactions, 
  },
  computed: {
    hubInfoLength() {
      if(this.$store.state.userDetail?.hubs) {
        return this.$store.state.userDetail?.hubs.length
      }
      return 1
    },
    hubInfo(): any {
      if(this.$store.state.userDetail?.hubs) {
        return this.$store.state.userDetail?.hubs[this.counter]
      }
      return null
    },
  },
  data() {
    return {
      triggerDestroy: true,
      counter: 0,
      selectedHub: null as any
    }
  },
  ionViewDidEnter() {
    this.cacheUsers()
    this.cacheWastes()
    
    this.scrollToTop()
    this.getHub()
    this.triggerDestroy = false
  },
  ionViewDidLeave() {
    this.selectedHub = null
    this.triggerDestroy = true
  },
  methods: {
    change(value: number) {
      this.counter = this.counter + value
    },
    isBound(value = 0) {
      return this.counter == value
    },
    getHub() {
      if(this.$store.state.userDetail?.hubs) {
        this.selectedHub = this.$store.state.userDetail?.hubs[this.counter]
      }
    },
    scrollToTop() {
      const elem = document.getElementById('hub-scroller') as any
      elem.scrollToTop()
    },
    async cacheUsers() {
        const uniquekey = 'cache:wma:all-users'
        const cached = localStorage.getItem(uniquekey)
        let parsed = {} as any
        if (cached) {
            parsed = JSON.parse(cached)
        }

        const ts = (new Date()).toDateString()
        if (navigator.onLine && parsed?.timestamp != ts) {
            try {
                const resp = await axios.get(''.concat(config['host_ip'], config['host_port'], "/cp-users"))
                localStorage.setItem(uniquekey, JSON.stringify({'list': resp.data, 'timestamp': ts}))
            } catch (err) { 
                console.log(err)
            }
        }
    },
    async cacheWastes() {
        const uniquekey = 'cache:wma:all-wastes'
        const cached = localStorage.getItem(uniquekey)
        let parsed = {} as any
        if (cached) {
            parsed = JSON.parse(cached)
        }

        const ts = (new Date()).toDateString()
        if (navigator.onLine && parsed?.timestamp != ts) {
            try {
                const resp = await axios.get(''.concat(config['host_ip'], config['host_port'], "/wastes"))
                localStorage.setItem(uniquekey, JSON.stringify({'list': resp.data, 'timestamp': ts}))
            } catch (err) { 
                console.log(err)
            }
        }
    }
  },
  watch: {
    hubInfo: {
      handler: function(newVal) {
        this.selectedHub = newVal
      }
    }
  }
});
