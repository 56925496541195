
import { defineComponent } from 'vue';
import axios from 'axios';
import config from '@/../public/assets/conf.json';

export default defineComponent({
    name: 'Your Stock',
    props: {
        hub: { type: Number, required: true, default: -1 },
        destroy: { type: Boolean, default: false }
    },
    data() {
        return {
            filteredList: [] as any[],
            stock: [] as any[],
            filter: ''
        }
    },
    methods: {
        async loadStock() {
            const uniquekey = 'cache:wma:stock-' + this.$props.hub
            const cached = localStorage.getItem(uniquekey)
            if (cached) {
                this.stock = JSON.parse(cached)
            }

            if (navigator.onLine) {
                try {
                    const resp = await axios.get(''.concat(config['host_ip'], config['host_port'], '/stock/', this.$props.hub.toString()))
                    localStorage.setItem(uniquekey, JSON.stringify(resp.data))
                    this.stock = resp.data
                } catch (err) { 
                    console.log(err)
                }
            }
                
            this.filterStock()
            //this.filteredList = this.stock.slice()
        },
        filterStock() {
            this.filteredList.splice(0, this.filteredList.length)
            
            this.filteredList = this.stock.filter( (item: any) => {
                return item.description.toLowerCase().indexOf(this.filter.toLowerCase()) > -1
            })
        },
        resetAllData() {
            this.filteredList = []
            this.stock = []
            this.filter = ''
        }
    },
    watch: {
        hub: {
            handler: function(newVal) {
                if(newVal > -1) {
                    this.loadStock()
                }
            }
        },
        destroy: {
            handler: function(newVal) {
                if (newVal) {
                    this.resetAllData()
                }
            }
        }
    }
})
