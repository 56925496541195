<template>
    <div class="card">
        <b class="uppercase">Sold Waste - </b><b>Last 30 days</b>
        <hr class="card-divider" />
        <ion-grid>
            <ion-row>
                <ion-col size="3.5">
                    <b>{{ overallSoldWaste }} kg</b>
                    <div class="mt-2">
                        <div class="mt-1" v-for="item of sold_waste" :key=item>
                            <text>
                                {{ getPercentage(item.total_amount) }} % {{ item.waste_type.attribute_data.name}}
                            </text>
                        </div>
                    </div>
                </ion-col>
                <ion-col size="0.5"></ion-col>
                <ion-col size="8">
                    <sold-waste-bar-chart :yvals="sold_waste"></sold-waste-bar-chart>
                </ion-col>
            </ion-row>
        </ion-grid>
    </div>
</template>

<script lang="ts">
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/camelcase */
import { IonGrid, IonRow, IonCol } from '@ionic/vue';
import { defineComponent } from 'vue';
import axios from 'axios';
import config from '@/../public/assets/conf.json';
import SoldWasteBarChart from '../charts/SoldWasteBarChart.vue';

export default defineComponent({
    name: 'Sold Waste',
    components: {
        IonGrid, IonRow, IonCol,
        SoldWasteBarChart
    },
    props: {
        hub: { type: Number, required: true, default: -1 },
        destroy: { type: Boolean, default: false }
    },
    data() {
        return {
            sold_waste: [] as any[],
            filter: ''
        }
    },
    methods: {
        async loadSoldWaste() {
            //load last 30 days then append
            const endDate: any = new Date
            const startDate = (new Date(endDate.getTime() - 30 * 24 * 60 * 60000)).toISOString().split('T')[0]
            
            const payload = {
                loc_id: this.$props.hub,
                start_date: startDate
            }

            const uniquekey = 'cache:wma:sold_waste-' + this.$props.hub
            const cached = localStorage.getItem(uniquekey)
            if (cached) {
                this.sold_waste = JSON.parse(cached)
            }

            if (navigator.onLine) {
                try {
                    const resp = await axios.post(''.concat(config['host_ip'], config['host_port'], '/get_sold_waste/'), payload)
                    localStorage.setItem(uniquekey, JSON.stringify(resp.data))
                    this.sold_waste = resp.data
                } catch (err) { 
                    console.log(err)
                }
            }
        },
        getPercentage(val: any) {
            return (val / this.overallSoldWaste * 100).toFixed(1)
        },
        resetAllData() {
            this.sold_waste = []
            this.filter = ''
        }
    },
    computed: {
        overallSoldWaste(): any {
            let sum = 0.0
            for(const waste_type of this.sold_waste) {
                sum += parseFloat(waste_type.total_amount)
            }
            return sum.toFixed(2)
        }
    },
    watch: {
        hub: {
            handler: function(newVal) {
                if (newVal > -1) {
                    this.loadSoldWaste()
                }
            }
        },
        destroy: {
            handler: function(newVal) {
                if (newVal) {
                    this.resetAllData()
                }
            }
        }
    }
})
</script>

<style scoped>
table {
  margin-bottom: 0;
  width: 100%;
}

table thead, table tbody tr {
    display:table;
    width:100%;
    table-layout:fixed;
}

.table-header {
    font-weight: bold;
    font-size: 15px;
}

.cell-border {
    border-bottom: 1px solid #a9d8f7;
    border-right: 1px solid #a9d8f7;
    border-left: 1px solid #a9d8f7;
    padding: 7px 0px 7px 7px;
}

.cell-border:first-child {
    border-left: 0;
}

.cell-border:last-child {
    border-right: 0;
}

table > tbody {
    max-height: 180px;
    height: 180px;
    overflow-y:scroll;
    display: block;
}

.hidescrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

::-webkit-scrollbar {
    width: 0px;  /* remove scrollbar space */
    background: transparent;  /* optional: just make scrollbar invisible */
}

.text-resize1_5v {
    font-size: 1.5vw;
}
</style>