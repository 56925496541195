
import { defineComponent } from 'vue';
import axios from 'axios';
import config from '@/../public/assets/conf.json';

export default defineComponent({
    name: 'Waste Availability',
    props: {
        hub: { type: Number, required: true, default: -1 },
        destroy: { type: Boolean, default: false }
    },
    data() {
        return {
            wasteTypes: [] as any[],
            stocks: [] as any[],
        }
    },
    methods: {
        async loadWasteAvailability() {
            this.resetAllData()

            const uniquekey = 'cache:wma:waste-availability-' + this.$props.hub
            const cached = localStorage.getItem(uniquekey)
            if (cached) {
                const parsed = JSON.parse(cached)
                this.wasteTypes = parsed.waste_types || []
                this.stocks = []
                for (const [key, value] of Object.entries(parsed.amounts)) {
                    this.stocks.push({'location': key, 'amounts': value})
                }
            }

            if (navigator.onLine) {
                try {
                    const resp = await axios.get(''.concat(config['host_ip'], config['host_port'], '/stock-overview/', this.$props.hub.toString()))
                    localStorage.setItem(uniquekey, JSON.stringify(resp.data))
                    this.wasteTypes = resp.data.waste_types || []
                    this.stocks = []
                    for (const [key, value] of Object.entries(resp.data.amounts)) {
                        this.stocks.push({'location': key, 'amounts': value})
                    }
                } catch (err) { 
                    console.log(err)
                }
            }
        },
        getWasteTypeValue(item: any, wasteType: string) {
            for(const entry of item) {
                if (entry.waste_type == wasteType) {
                    return entry.total_amount + ' ' + entry.measure_unit
                }
            }
            return '-'
        },
        resetAllData() {
            this.wasteTypes = []
            this.stocks = []
        }
    },
    watch: {
        hub: {
            handler: function(newVal) {
                if(newVal > -1) {
                    this.loadWasteAvailability()
                }
            }
        },
        destroy: {
            handler: function(newVal) {
                if (newVal) {
                    this.resetAllData()
                }
            }
        }
    }
})
