<template>
  <ion-page>
    <ion-content id="hub-scroller">
      <ion-grid>
        <ion-row class="mt-2" style="justify-content: center">
            <ion-col size-xl="0.5" size-xs="0.1"></ion-col>
            <ion-col size="11">
              <ion-row style="justify-content: center">
                <div v-if="hubInfoLength > 1" :class="[isBound() ? 'disabled' : 'enabled']">
                  <ion-icon  style="margin-right: 10px; font-size: 1vw;" @click="change(-1)" name="ch-back"></ion-icon>
                </div>

                <b>{{ selectedHub?.name || '' }} - {{ selectedHub?.locationtype.name || '' }}</b>
                
                <div v-if="hubInfoLength > 1" :class="[isBound(hubInfoLength - 1) ? 'disabled' : 'enabled']">
                  <ion-icon style="margin-left: 10px; font-size: 1vw;" @click="change(1)" name="ch-forward"></ion-icon>
                </div>
              </ion-row>

              <ion-row class="mt-2">
                <ion-col size-xl="5.8" size-xs="12">
                  <stock :destroy="triggerDestroy" :hub="selectedHub?.id"></stock> 
                </ion-col>
                <ion-col class="ion-hide-xl-down" size="0.2"></ion-col>
                <ion-col size-xl="6" size-xs="12">
                  <waste-availability :destroy="triggerDestroy" :hub="selectedHub?.id"></waste-availability>
                </ion-col>
              </ion-row>

              <ion-row class="mt-2">
                <ion-col size-lg="2.3" size-xs="12">
                  <balance :destroy="triggerDestroy" :hub="selectedHub?.id"></balance>
                </ion-col>
                <ion-col class="ion-hide-xl-down" size="0.2"></ion-col>
                <ion-col size-lg="4.9" size-xs="12">
                  <sold-waste :destroy="triggerDestroy" :hub="selectedHub?.id"></sold-waste>
                </ion-col>
                <ion-col class="ion-hide-xl-down" size="0.2"></ion-col>
                <ion-col size-lg="4.4" size-xs="12">
                  <price-list :destroy="triggerDestroy" :hub="selectedHub?.id"></price-list>
                </ion-col>
              </ion-row>

              <ion-row class="mt-2 ion-hide-md-down">
                <ion-col size="12">
                  <transactions :destroy="triggerDestroy" :hub="selectedHub?.id"></transactions> 
                </ion-col>
              </ion-row>

            </ion-col>
            <ion-col size-xl="0.5" size-xs="0.1"></ion-col>
          </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { IonPage, IonContent, IonGrid, IonCol, IonRow, IonIcon } from '@ionic/vue';
import { defineComponent } from 'vue';
import Stock from './components/ownLocation/Stock.vue';
import Balance from './components/ownLocation/Balance.vue';
import PriceList from './components/ownLocation/PriceList.vue';
import SoldWaste from './components/ownLocation/SoldWaste.vue';
import WasteAvailability from './components/ownLocation/WasteAvailability.vue';
import Transactions from './components/ownLocation/Transactions.vue';
import config from '@/../public/assets/conf.json';
import axios from 'axios';

export default defineComponent({
  name: 'Your Hub',
  components: {
    IonPage, IonContent, IonGrid, IonCol, IonRow, IonIcon,
    WasteAvailability, Stock, Balance, PriceList, SoldWaste, Transactions, 
  },
  computed: {
    hubInfoLength() {
      if(this.$store.state.userDetail?.hubs) {
        return this.$store.state.userDetail?.hubs.length
      }
      return 1
    },
    hubInfo(): any {
      if(this.$store.state.userDetail?.hubs) {
        return this.$store.state.userDetail?.hubs[this.counter]
      }
      return null
    },
  },
  data() {
    return {
      triggerDestroy: true,
      counter: 0,
      selectedHub: null as any
    }
  },
  ionViewDidEnter() {
    this.cacheUsers()
    this.cacheWastes()
    
    this.scrollToTop()
    this.getHub()
    this.triggerDestroy = false
  },
  ionViewDidLeave() {
    this.selectedHub = null
    this.triggerDestroy = true
  },
  methods: {
    change(value: number) {
      this.counter = this.counter + value
    },
    isBound(value = 0) {
      return this.counter == value
    },
    getHub() {
      if(this.$store.state.userDetail?.hubs) {
        this.selectedHub = this.$store.state.userDetail?.hubs[this.counter]
      }
    },
    scrollToTop() {
      const elem = document.getElementById('hub-scroller') as any
      elem.scrollToTop()
    },
    async cacheUsers() {
        const uniquekey = 'cache:wma:all-users'
        const cached = localStorage.getItem(uniquekey)
        let parsed = {} as any
        if (cached) {
            parsed = JSON.parse(cached)
        }

        const ts = (new Date()).toDateString()
        if (navigator.onLine && parsed?.timestamp != ts) {
            try {
                const resp = await axios.get(''.concat(config['host_ip'], config['host_port'], "/cp-users"))
                localStorage.setItem(uniquekey, JSON.stringify({'list': resp.data, 'timestamp': ts}))
            } catch (err) { 
                console.log(err)
            }
        }
    },
    async cacheWastes() {
        const uniquekey = 'cache:wma:all-wastes'
        const cached = localStorage.getItem(uniquekey)
        let parsed = {} as any
        if (cached) {
            parsed = JSON.parse(cached)
        }

        const ts = (new Date()).toDateString()
        if (navigator.onLine && parsed?.timestamp != ts) {
            try {
                const resp = await axios.get(''.concat(config['host_ip'], config['host_port'], "/wastes"))
                localStorage.setItem(uniquekey, JSON.stringify({'list': resp.data, 'timestamp': ts}))
            } catch (err) { 
                console.log(err)
            }
        }
    }
  },
  watch: {
    hubInfo: {
      handler: function(newVal) {
        this.selectedHub = newVal
      }
    }
  }
});
</script>

<style scoped>
.disabled {
  pointer-events: none;
  color: lightgrey;
}

.enabled {
  pointer-events: all;
  color: black;
}
</style>
