<template>
    <div class="card">
        <b class="uppercase">Waste Availability</b><b> - Other Locations</b>
        <hr class="card-divider" />
        <div class="wrapper mt-1">
            <table id="waste-content" style="width: 100%">
                <thead>
                    <tr class="table-header">
                        <th class="cell-border">Waste Type</th>
                        <th class="cell-border" v-for="item of stocks" :key="item">{{ item.location }}</th>
                    </tr>
                </thead>
                <tbody class="hidescrollbar">
                    <tr v-for="item of wasteTypes" :key="item">
                        <td class="cell-border">{{ item }}</td>
                        <td class="cell-border" v-for="loc of stocks" :key="loc">{{ getWasteTypeValue(loc.amounts, item) }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import axios from 'axios';
import config from '@/../public/assets/conf.json';

export default defineComponent({
    name: 'Waste Availability',
    props: {
        hub: { type: Number, required: true, default: -1 },
        destroy: { type: Boolean, default: false }
    },
    data() {
        return {
            wasteTypes: [] as any[],
            stocks: [] as any[],
        }
    },
    methods: {
        async loadWasteAvailability() {
            this.resetAllData()

            const uniquekey = 'cache:wma:waste-availability-' + this.$props.hub
            const cached = localStorage.getItem(uniquekey)
            if (cached) {
                const parsed = JSON.parse(cached)
                this.wasteTypes = parsed.waste_types || []
                this.stocks = []
                for (const [key, value] of Object.entries(parsed.amounts)) {
                    this.stocks.push({'location': key, 'amounts': value})
                }
            }

            if (navigator.onLine) {
                try {
                    const resp = await axios.get(''.concat(config['host_ip'], config['host_port'], '/stock-overview/', this.$props.hub.toString()))
                    localStorage.setItem(uniquekey, JSON.stringify(resp.data))
                    this.wasteTypes = resp.data.waste_types || []
                    this.stocks = []
                    for (const [key, value] of Object.entries(resp.data.amounts)) {
                        this.stocks.push({'location': key, 'amounts': value})
                    }
                } catch (err) { 
                    console.log(err)
                }
            }
        },
        getWasteTypeValue(item: any, wasteType: string) {
            for(const entry of item) {
                if (entry.waste_type == wasteType) {
                    return entry.total_amount + ' ' + entry.measure_unit
                }
            }
            return '-'
        },
        resetAllData() {
            this.wasteTypes = []
            this.stocks = []
        }
    },
    watch: {
        hub: {
            handler: function(newVal) {
                if(newVal > -1) {
                    this.loadWasteAvailability()
                }
            }
        },
        destroy: {
            handler: function(newVal) {
                if (newVal) {
                    this.resetAllData()
                }
            }
        }
    }
})
</script>

<style scoped>
.wrapper {
  overflow: auto;
  height: 270px;
  width: 100%;
}

/*table {
  margin-bottom: 0;
  width: 100%;
}*/

.table-header {
    font-weight: bold;
    font-size: 15px;
}

.cell-border {
    border-bottom: 1px solid #a9d8f7;
    border-right: 1px solid #a9d8f7;
    border-left: 1px solid #a9d8f7;
    padding: 7px 7px 7px 7px;
}

.cell-border:first-child {
    border-left: 0;
    position: sticky;
}

.cell-border:last-child {
    border-right: 0;
}

table th,
table td {
  width: 2.6vw;
  padding: 5px;
  background-color: white;
}

table th {
  text-align: center;
  position: sticky;
  top: 0;
  min-width: 7.8vw;
}

table th:nth-child(1) {
  left: 0;
  z-index: 3;
}

table td {
  text-align: center;
  white-space: pre;
}

table tbody tr td:nth-child(1) {
  position: sticky;
  left: 0;
  z-index: 1;
}

::-webkit-scrollbar{
    width: 0px;
    height: 8px;
}
::-webkit-scrollbar-track{
    background: #f1f1f1;
}
::-webkit-scrollbar-thumb{
    background: #9cc7ec;
    border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover{
    background: #81b9ee;
}
</style>
